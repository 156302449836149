import React,{useState} from 'react'
import {Swiper,SwiperSlide} from "swiper/react";
import {Navigation,Pagination,Autoplay} from "swiper";
// resources
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {faChevronRight} from '@fortawesome/free-solid-svg-icons'
import ImgWithFallback from './ImgWithFallback';
import useWindowSize from '../hooks/useWindowSize';

export const ProductImageCarousel=({productCarouselData}) => {
    const [swiper,setSwiper]=useState(null);
    const handleMouseEnter=() => {
    }
    const handleMouseLeave=() => {
    }
    const navigation={
        nextEl: '.product-image__carousel-arrow--next',
        prevEl: '.product-image__carousel-arrow--prev'
    }
    const productCarouselSlides=productCarouselData.items
    const {width}=useWindowSize()
    return (
        <section onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
            <Swiper
                modules={[Navigation,Pagination,Autoplay]}
                navigation={navigation}
                pagination={{clickable: true}}
                initialSlide={0}
                speed={500}
                slidesPerView={1}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: true
                }}
                loop={true}
                onSwiper={setSwiper}
                className='product-image-carousel'
            >
                {
                    productCarouselSlides?.map((item,index) => {
                        return (
                            <SwiperSlide key={`${item.slide_type}-${index}`} className='position-relative product-image-slide'
                                style={
                                    (item?.product_bg_image?.url
                                        ?
                                        {backgroundImage: `url(${width>=991? item?.product_bg_image?.url:item?.product_bg_image_mobile?.url})`,backgroundSize: "cover",backgroundRepeat: "no-repeat",backgroundPosition: "center"}
                                        :
                                        null
                                    )
                                }>
                                {
                                    width>=991&&item?.product_image?.url!==null&&
                                    <ImgWithFallback
                                        classNamePicture={"product-image-carousel__item-pct"}
                                        classNameImg={"product-image-carousel__item-img"}
                                        src={item?.product_image?.url}
                                        fallbackSrc={item?.product_image?.url}
                                        alt={item?.product_image?.alt}
                                    />
                                }
                                {
                                    width<991&&item?.product_image_mobile?.url&&
                                    <ImgWithFallback
                                        classNamePicture={"product-image-carousel__item-pct"}
                                        classNameImg={"product-image-carousel__item-img"}
                                        src={item?.product_image_mobile?.url}
                                        fallbackSrc={item?.product_image_mobile?.url}
                                        alt={item?.product_image?.alt}
                                    />
                                }
                            </SwiperSlide>
                        )
                    })
                }
                <button
                    className="product-image__carousel-arrow product-image__carousel-arrow--prev"
                >
                    <span className="visually-hidden">Prev</span>
                    <FontAwesomeIcon icon={faChevronLeft} className="fa-solid product-image-carousel-icon" size="2xl" />
                </button>
                <button
                    className="product-image__carousel-arrow product-image__carousel-arrow--next"
                >
                    <span className="visually-hidden">Next</span>
                    <FontAwesomeIcon icon={faChevronRight} className="fa-solid product-image-carousel-icon" size="2xl" />
                </button>
            </Swiper>
        </section>
    )
}
