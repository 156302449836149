// core
import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from "@reach/router";
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { PrismicRichText, SliceZone } from '@prismicio/react'
import { scroller } from "react-scroll";
import { useSiteMetadata } from "../hooks/useSiteMetadata";
import axios from 'axios'

// components
import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import ImgWithFallback from '../components/ImgWithFallback'
import CustomLink from "../components/CustomLink";
import Reviews from "../components/Reviews";
import { pdpComponents } from '../slices/product-details'
import ProductDetailsModules from '../components/ProductDetailsModules'
import { useConsent } from '../components/ConsentContext'
import Locator from '../components/Locator'

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

// data-layer
import {
  setBuyNowClickedEvent,
  setLearnMoreClickedEvent,
  setNutritionIngClickedEvent,
  setProductFlavorViewedEvent,
  setProductFlavorClickedEvent,
  setLocatorPageViewEvent,
  setRetailerSelectedEvent,
  setLocatorAddressSearchedEvent,
  setGetDirectionsEvent
} from "../assets/js/data-layer";

import useWindowSize from '../hooks/useWindowSize';

// constants
import { LANG } from "../constants/languages";
import { mikMakLocatorScript } from "../constants/mikMak";
import { PRODUCT_EARNS } from '../constants/productEans';
import { GOOGLE_MAP_API_URL } from '../constants/baseUrl'
import { useCookies } from "react-cookie";
import {ProductImageCarousel} from '../components/ProductImageCarousel';

const ProductDetails = ( {data} ) => {
  const [cookies, setCookie] = useCookies()
  const location = useLocation()
  const isBrowser = typeof window !== "undefined"

  const pageContent = data?.prismicProductDetailsPage
  const pageData = data?.prismicProductDetailsPage?.data
  const siteData = data?.site?.siteMetadata
  const sliceData = pageData?.body
  const awardsPage=sliceData?.find(
    (item) => item.slice_type==="award_list"
  )
  const awardPageData = awardsPage?.items
  const isVodkaSoda = pageContent?.uid?.includes('vodka-soda') ? true : undefined

  const showButtonsGroup = pageData?.cta_button_text?.text || ''
  const consentAccepted = useConsent()


  // console.log('data ' , data)

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent?.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  // GTINs for MIKMAK widget
  const productId = pageData?.product_id || ''

  // select first GTIN for BV widget
  const productIdBV = productId?.split(",").splice(0, 1).join("")

  const [selectedNutritionSlice, setSelectedNutritionSlice] = useState([])
  const [isTableActive, setTableActive] = useState("false")
  const ToggleTableClass = () => {
    setTableActive(!isTableActive)

    // nutrition_ingredients_clicked data-layer
    const product  = {
      name: pageData?.product_name.text,
      category: pageData?.product_category?.document?.data?.category_name?.toLowerCase()
    }
    setNutritionIngClickedEvent(product, consentAccepted)
  }

  const [isServingActive, setServingActive] = useState("false")
  const ToggleServingClass = () => {
    setServingActive(!isServingActive)
  }
  const [userSelection, setUserSelection] = useState(null)

  const productSizes = {
    can12: {
      userFmt: '1 can (12 oz)',
      sliceName: '1_can__12_oz_'
    },
    can16: {
      userFmt: '1 can (16 oz)',
      sliceName: '1_can__16_oz_'
    },
    can19: {
      userFmt: '1 can (19.2 oz)',
      sliceName: '1_can__19.2_oz_'
    },
    can24: {
      userFmt: '1 can (24 oz)',
      sliceName: '1_can__24_oz_'
    },
    btl15: {
      userFmt: '1 bottle (1.5 FL. OZ)',
      sliceName: '1_bottle__1.5_fl._oz_'
    },
  }

  const checkForSlice = (sliceToCheck) => {
    let sliceExist = false
    sliceData.forEach((element) => {
      if(element.slice_type === sliceToCheck) {
        sliceExist = true
      }
    })
    return sliceExist
  }

  const getNutritionSliceToRender = (selectedSize) => {
    sliceData.forEach((element) => {
      for(const productItem in productSizes) {
        if(element.slice_label === productSizes[productItem].sliceName && selectedSize === productSizes[productItem].userFmt ) {
          setSelectedNutritionSlice([element])
        }
      }
    })
  }

  const renderSliceType = (type) => {
    const sliceToRender = []
    sliceData.forEach((element) => {
      if(element.slice_type === type ) {
        sliceToRender.push(element)
      }
    })
    return sliceToRender
  }

  useEffect(() => {
    if (pageData.serving_size_list.length > 0) {
      const matchingSize = pageData.serving_size_list.find((size) => {
        return Object.values(productSizes).some((productSize) => productSize.userFmt === size.sizes);
      });
      if (matchingSize) {
        const matchedProductSize = Object.values(productSizes).find(
          (productSize) => productSize.userFmt === matchingSize.sizes
        );
        setUserSelection(matchedProductSize.userFmt);
      } else {
        // If no match is found, default to a known size (like the 12 oz can)
        setUserSelection(productSizes.can12.userFmt);
      }
    }
    setSelectedNutritionSlice([sliceData[0]])

    const category = pageContent?.data?.product_category?.document?.data?.category_name?.toLowerCase() || ''
    const flavor = pageContent?.data?.taxonomy_product_flavor
    const size = pageContent?.data?.taxonomy_product_size

    setProductFlavorViewedEvent({ flavor, category, size }, consentAccepted)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageContent, consentAccepted])


  useEffect(()=>{

    if(location.hash === '#find-near-you'){
      scroller.scrollTo(`locator-anchor`, {
        duration: 1000,
        delay: 50,
        smooth: true,
      });
    }

  }, [])

  // data layer - locator pageview event
  useEffect(() => {
    const dlData = {
      title: pageData?.meta_title?.text || siteData?.title,
      url: location.href,
      path: location.pathname,
      referrer: (sessionStorage.getItem('previous_page_url'))?
        sessionStorage.getItem('previous_page_url') : location.pathname,
      language: lang
    }

    setLocatorPageViewEvent(dlData, consentAccepted)
  }, [pageContent, consentAccepted])

  // pixels Pinterest and Snapchat
  useEffect(() => {

    if(isBrowser && window.pintrk){
      window.pintrk('track', 'viewcategory');
    }

  }, [])

  // push product Ids (GTINs) to mikmak dataLayer and added TAG
  useEffect(()=>{

    const script = document.createElement("script")
    script.type = 'text/javascript'
    script.innerText = `
      window.swnTagLoaded = false,
      window.swnDataLayer = { product_eans: "${productId}" };
      ${ mikMakLocatorScript }
    `

    document.body.appendChild(script)

    if(isBrowser && window.swnDataLayer){

      window.swnDataLayer.widget_callback = async function(data){
        if(data.action === 'buy') {
          // retailer selected event
          const supplier = data.store.name.toLowerCase()
          const flavor = pageContent.data.taxonomy_product_flavor
          const category = pageContent.data.product_category.document.data.category_name.toLowerCase() || ''
          const size = data.product.pkg?data.product.pkg.toLowerCase() : ''
          const price = `${data.product.price}`
          const method = 'online'

          setRetailerSelectedEvent({
            'supplier': supplier,
            'flavor': flavor,
            'category': category,
            'size': size,
            'price': price,
            'method': method
          }, consentAccepted)
        }else if(data.action === 'clkRoute'){

          const supplier = data.store.name.toLowerCase()
          setGetDirectionsEvent({
            'supplier': supplier,
            'pageURL': location.href
          }, consentAccepted)
        }else if(data.action === 'geoloc'){
          // locator address searched event
          const zipCode = data.location.zipCode
          if(zipCode){
            setLocatorAddressSearchedEvent  ({
              'zc': data.location.zipCode,
              'cty': data.location.city,
              'st': data.location.region,
              'ctry': data.location.country,
              'addr': data.location.address,
            }, consentAccepted)
           // const locationData = await getAddressFromZipCode(zipCode)
            //setLocatorAddressSearchedEvent(locationData, consentAccepted)
          }
        }

        if (data.action === 'buy') {
          window.snaptr('track', 'PURCHASE')
          window.pintrk('track', 'addtocart', { value: 10.00, order_quantity: 1, currency: 'USD'});
        }

        if (data.action === 'clkRoute') {
          window.snaptr('track', 'ADD_CART')
          window.pintrk('track', 'addtocart', { value: 10.00, order_quantity: 1, currency: 'USD'});
        }
      }
    }

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script)
    }
  },[])

const simulateButtonClick = () => {
  const bvButton = document.querySelector('.bv_war_button');
  if (bvButton) {
    bvButton.click();
  } else {
    console.error("BV Button Not Found!");
  }
};
  // added bazaarvoice script
  useEffect(()=>{

    const script = document.createElement("script")
    script.src = "https://apps.bazaarvoice.com/deployments/white_claw/main_site/production/en_US/bv.js"
    script.async = true
    document.head.appendChild(script)
    script.onload = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const leaveReviewParam = urlParams.has('leaveReview');

      if (leaveReviewParam && cookies?.adult) {
        const interval = setInterval(() => {
          const bvButton = document.querySelector('.bv_war_button');
          if (bvButton) {
            simulateButtonClick();
            clearInterval(interval);
          }
        }, 500);
      }
      const observer=new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
          const pdpRatingSummary=document.querySelector('.pdp-rating-summary');
          if(pdpRatingSummary) {
            const bazaarvoiceButtonContainer=document.querySelector('.bv_button_component_container');
            const bazaarvoiceRatingButtonContainer=document.querySelector('.bv_main_container_row_flex');
            if(bazaarvoiceButtonContainer) {
              const button=bazaarvoiceButtonContainer.querySelector('button');
              if(button) {
                button.style.setProperty('text-transform','uppercase','important');
                button.style.setProperty('font-size','17px','important');
                button.style.setProperty('font-weight','bold','important');
                button.style.setProperty('margin-top','4px','important');
                button.style.setProperty('transition', 'text-decoration 0.6s', 'important');
                button.addEventListener('mouseover',() => {
                  button.style.setProperty('text-decoration','underline','important');
                });
                button.addEventListener('mouseout',() => {
                  button.style.setProperty('text-decoration','none','important');
                });
              }
            }
            if(bazaarvoiceRatingButtonContainer) {
              const ratingButton=bazaarvoiceRatingButtonContainer.querySelector('.bv_numReviews_component_container');
              const averageRating=document.querySelector('.bv_avgRating_component_container');
              const ratingText=ratingButton.querySelector('.bv_numReviews_text');
              if(ratingText) {
                ratingText.style.setProperty('font-size','17px','important');
                ratingText.style.setProperty('margin-top','4px','important');
              }
              if(averageRating){
                averageRating.style.setProperty('font-size','17px','important');
                averageRating.style.setProperty('margin-top','4px','important');
              }
            }
          }
        })
      })
      observer.observe(document.body,{
        childList: true,
        subtree: true,
      });
    };

    return () => {
      // clean up the script when the component in unmounted
      document.head.removeChild(script)
    }

  }, [cookies?.adult])

  // bazaarvoice verify schema
  const schema = {
    "@context" : "https://schema.org",
    "@type" : "Product",
    "@id" : `${location.href}/`,
    "name" : `${pageData.product_name.text}`,
    "image" : [`${pageData.product_image_png.url}`],
    "description" : `${pageData.product_description.richText[0]?.text || ''}`,
    "brand": {
      "@type": "Brand", // brand name
      "name": "White Claw", // range name
      "productID": `${productIdBV}`,
      "sku": `${productIdBV}`
    },
    "availability": "https://schema.org/InStock ",
    "Url":`${location.href}`
  }

  const getAddressFromZipCode = async (zipcode) => {
    try{

      const response = await axios({
        url: GOOGLE_MAP_API_URL('AIzaSyBdKe9lOFpOiN8Wu51RzCnIXpiV3bf61J8', zipcode),
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      })

      if(response.status == 200){
        const result = response.data.results[0]
        if(!result){
          throw new Error('No address found.')
        }

        return result.address_components.reduce((cus, component) => {
          const types = component.types
          if(types.includes('postal_code')){
            cus.zc = component.long_name
          }else if(types.includes('locality')){
            cus.cty = component.long_name
          }else if(types.includes('country')){
            cus.ctry = component.short_name
          }else if(types.includes('administrative_area_level_1')){
            cus.st = component.long_name
          }
          return cus
        }, { addr: result.formatted_address })
      }else{
        throw new Error(`Error: ${response.status}, ${response.statusText}`)
      }
    }catch(err){
      console.error(err.message)
      return null
    }
  }

  const schemaAsString = JSON.stringify(schema, null, 2);
  const { width } = useWindowSize()
  const [loadMore,setLoadMore]=useState(false);
  const [isLoaded,setIsLoaded]=useState(false);
  const loadCount = loadMore ?  awardPageData.length: 3;
  const loadCountMobile = loadMore ?  awardPageData.length: 2;

  const showMoreAwards=()=>{
    setLoadMore(!loadMore)
  }


  const setConversionData = (conversionData) => {
    window.BV.pixel.trackConversion(conversionData);
  }

  const convertToDashSeparated = (str) => {
    return str.replace(/\s+/g, '-');
  }
  const productCarouselData=sliceData?.find(
    (item) => item.slice_type==="product_image_carousel"
  )
    return (
      <Layout currentPage='product-details' activeDocMeta={activeDoc}  setPreviousPage={false}>
        <Seo
            title={pageData.meta_title?.text }
            description={pageData.meta_description?.text}
            image={pageData.social_card?.url}
            activeDocMeta={activeDoc}
            currentPage={convertToDashSeparated(pageData.taxonomy_product_flavor)}
        >
          { lang === LANG.EN && <script type="application/ld+json">{schemaAsString}</script> }
        </Seo>
        <section className="inside-content product-details">
          <div className="container-fluid inside-content-row py-0">
            <div className="row">
              <div className="col-lg-6 p-0">
                <div className={`product-image${pageData?.product_type === 'Can' ? ' product-image--can': ''}${pageData?.product_type === 'Bottle' ? ' product-image--bottle': ''}${pageData?.product_type === 'Pack' ? ' product-image--pack': ''}`}>
                  {
                    productCarouselData!=undefined?
                      <ProductImageCarousel productCarouselData={productCarouselData} />
                      :
                      <div>
                        <ImgWithFallback
                          classNamePicture={"product-image__bg-pct"}
                          classNameImg={"product-image__bg-img"}
                          src={pageData?.product_bg_png?.url}
                          fallbackSrc={pageData?.product_bg_png?.url}
                          alt={pageData?.product_bg_png?.alt}
                        />
                        <ImgWithFallback
                          classNamePicture={"product-image__item-pct"}
                          classNameImg={"product-image__item-img"}
                          src={pageData?.product_image_png?.url}
                          fallbackSrc={pageData?.product_image_png?.url}
                          alt={pageData?.product_image_png?.alt}
                        />
                      </div>
                  }
                </div>
              </div>
              <div className="col-lg-6 p-0">
                <div className="product-content">
                  <h1 className="product-name-title" style={{color: pageData?.product_name_color}}>
                    <PrismicRichText field={pageData?.product_name?.richText}/>
                  </h1>

                  { lang === LANG.EN && <div
                      data-bv-show="rating_summary"
                      data-bv-product-id={productIdBV}
                      className='pdp-rating-summary'
                  >
                  </div> }

                  { showButtonsGroup &&  <div className='d-flex product-buttons-wrap'>
                    <CustomLink
                        className="product-action-btn mr-3"
                        onClick={()=> {
                          scroller.scrollTo(`locator-anchor`, {
                            duration: 1000,
                            delay: 50,
                            smooth: true,
                          });
                          const dl = {
                            flavor: pageData?.product_name?.text?.toLowerCase(),
                            category: pageData?.product_category?.document?.data?.category_name?.toLowerCase()
                          }

                          // TODO: clarify what type of values we are expecting
                          setConversionData({
                            "type" : "WhereToBuy",
                            "label" : '',
                            "value" : ''
                          })

                          setBuyNowClickedEvent(dl, consentAccepted)

                          // TODO: clarify what type of values we are expecting
                          setConversionData({
                            "type" : "WhereToBuy",
                            "label" : '',
                            "value" : ''
                          })

                        }}
                        >
                        {pageData?.product_action_title}
                    </CustomLink>

                    <CustomLink
                        to={pageData?.cta_button_link?.url}
                        className="product-action-btn"
                        onClick={() => {
                            const dl = {
                              url: pageData?.cta_button_link?.url,
                              referrer: location?.pathname,
                              name: pageData?.cta_button_text?.text?.toLowerCase(),
                              page_name:pageContent?.uid
                            }
                            setLearnMoreClickedEvent(dl, consentAccepted)
                          }
                        }
                    >
                      {pageData?.cta_button_text?.text}
                    </CustomLink>
                  </div> }

                  { !showButtonsGroup &&
                      <CustomLink
                          className="product-action-btn"
                          onClick={()=> {
                            scroller.scrollTo(`locator-anchor`, {
                              duration: 1000,
                              delay: 50,
                              smooth: true,
                            });
                            const dl = {
                              flavor: pageData?.product_name?.text?.toLowerCase(),
                              category: pageData?.product_category?.document?.data?.category_name?.toLowerCase()
                            }

                            setBuyNowClickedEvent(dl, consentAccepted)

                            // TODO: clarify what type of values we are expecting
                            setConversionData({
                              "type" : "WhereToBuy",
                              "label" : '',
                              "value" : ''
                            })

                          }}
                      >
                        {pageData?.product_action_title}
                      </CustomLink> }

                  <p className="product-description">
                    <PrismicRichText field={pageData?.product_description?.richText}/>
                  </p>
                  <a id='locator-anchor' className='visually-hidden'>Anchor Link</a>
                  <Locator/>
                  {
                    pageData?.product_info?.length !== 0 ?
                    <div className={`product-info${pageData?.product_category?.document?.data?.category_info_subtitle?.richText?.length === 0 ? ' mb-5' : ''}`}>
                      {pageData.product_info?.map((element, index) => {
                        return (
                          <div className="product-info__item" key={index}>
                            <img className="product-info__icon" src={element?.product_info_icon?.url} alt={element?.product_info_icon?.alt} />
                            <div className="product-info__name">{element?.product_info_name}</div>
                          </div>
                        );
                      })}
                    </div>
                  :
                    <div className={`product-info${pageData?.product_category?.document?.data?.category_info_subtitle?.richText?.length === 0 ? ' mb-5' : ''}`}>
                      {pageData?.product_category?.document?.data?.category_info?.map((element, index) => {
                        return (
                          <div className="product-info__item" key={index}>
                            <img className="product-info__icon" src={element?.info_image?.url} alt={element?.info_image?.alt} />
                            <div className="product-info__name">{element?.info_text}</div>
                          </div>
                        );
                      })}
                    </div>
                  }
                  {pageData?.product_category?.document?.data?.category_info_subtitle?.richText?.length !== 0 ?
                    <div className='product-info__subtitle'>
                      <PrismicRichText field={pageData?.product_category?.document?.data?.category_info_subtitle?.richText}/>
                    </div>
                    : null
                  }
                  {
                    checkForSlice('nutrition_table') ?
                    <section className="product-nutrition">
                    <h2>
                      <button className="product-nutrition__toggle" onClick={ToggleTableClass}>
                        {pageData?.table_title}
                        <FontAwesomeIcon
                            icon={faChevronDown}
                            className="fa-solid"
                            size="lg"
                            flip={!isTableActive ? "vertical" : false}
                           />
                      </button>
                    </h2>
                    <table id="product-nutrition-table" className={`product-nutrition__table${!isTableActive ? " is-open" : ""}`} data-serving-size-selected>
                      <tbody>
                        <tr className="product-nutrition__row product-nutrition__row--dark-underline">
                          <td className="product-nutrition__row-left product-nutrition__serving-title">{pageData?.serving_size_title}</td>
                          <td className="product-nutrition__row-right">
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
                            <div role="button" className="product-nutrition__serving-toggle" onClick={ToggleServingClass}>
                              <div id="product-nutrition-selected" className="product-nutrition__serving-selected" aria-selected="true">
                                {userSelection}
                              </div>
                              {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
                              <div role="menu" aria-expanded="true" className={`product-nutrition__serving-menu${!isServingActive ? " is-open" : ""}`}>
                                {pageData?.serving_size_list?.length !== 1 && pageData?.serving_size_list?.map((element, index) => {
                                  return (
                                      <div // eslint-disable-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
                                        role="menuitem"
                                        key={index}
                                        className="product-nutrition__serving-item"
                                        onClick={(e) => {
                                          const value = e.target.textContent;
                                          document.getElementById("product-nutrition-selected").textContent = value;
                                          getNutritionSliceToRender(value);
                                        }}
                                      >
                                        {element.sizes}
                                      </div>
                                    );
                                })}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <SliceZone slices={selectedNutritionSlice} components={pdpComponents} />
                    </table>
                  </section>
                  : null
                  }
                  <section className="product-quantities">
                    {pageData?.product_sizes?.map((element, index) => {
                      if (index === 0) {
                        return (
                          <div key={index} className="product-quantities__row product-quantities__row--first-sizes">
                            <div className="product-quantities__col">
                              <h2 className="product-quantities__title">{pageData?.product_sizes_title}</h2>
                            </div>
                            <div className="product-quantities__col product-quantities__col--icon">
                              <img src={element?.product_size_icon?.url} alt={element?.product_size_icon?.alt} className="product-quantities__icon product-quantities__icon--sizes" />
                            </div>
                            <div className="product-quantities__col">
                              <div className="product-quantities__text">{element?.product_size}</div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div key={index} className="product-quantities__row">
                            <i></i>
                            <div className="product-quantities__col product-quantities__col--icon">
                              <img src={element?.product_size_icon?.url} alt={element?.product_size_icon?.alt} className="product-quantities__icon product-quantities__icon--sizes" />
                            </div>
                            <div className="product-quantities__col">
                              <div className="product-quantities__text">{element?.product_size}</div>
                            </div>
                          </div>
                        );
                      }
                    })}
                    {pageData?.product_packs?.map((element, index) => {
                      if (index === 0) {
                        return (
                          <div key={index} className="product-quantities__row product-quantities__row--first-packs">
                            <div className="product-quantities__col">
                              <h2 className="product-quantities__title">{pageData?.product_packs_title}</h2>
                            </div>
                            <div className="product-quantities__col product-quantities__col--icon">
                              <img src={element?.product_pack_icon?.url} alt={element?.product_pack_icon?.alt} className="product-quantities__icon product-quantities__icon--packs" />
                            </div>
                            <div className="product-quantities__col">
                              <div className="product-quantities__text">{element?.product_pack}</div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div key={index} className="product-quantities__row">
                            <i></i>
                            <div className="product-quantities__col product-quantities__col--icon">
                              <img src={element?.product_pack_icon?.url} alt={element?.product_pack_icon?.alt} className="product-quantities__icon product-quantities__icon--packs" />
                            </div>
                            <div className="product-quantities__col">
                              <div className="product-quantities__text">{element?.product_pack}</div>
                            </div>
                          </div>
                        );
                      }
                    })}
                  </section>
                  {lang===LANG.EN?
                  checkForSlice("award_list") && awardsPage?.primary?.show_awards && awardPageData?.length > 0 ?
                  <section className='awards-section'>
                    <h2 className='award-heading'>AWARDS</h2>
                    <div className='row'>
                      {
                        awardPageData?.slice(0,width>=991? loadCount:loadCountMobile)?.map((data,index) => (
                          <div className='col-lg-4 col-6'>
                            <div>
                              <img src={data?.award_image?.url} className='award-image-common award_image' />
                            </div>
                            <div>
                              <img src={data?.award_sub_image?.url} className='award-image-common award_sub_image' />
                            </div>
                            <div className='award-title-container'>
                              <h4 className='award-title'>{data?.award_title?.text}</h4>
                            </div>
                            <div className='award-subtitle-container'>
                              <p className='award-subtitle'>{data?.award_sub_title?.text}</p>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                        {
                          width>=991?
                            awardPageData?.length>3?
                              <div className='text-center see-more-btn'>
                                <a onClick={() => showMoreAwards()}>{loadMore? "SEE LESS":"SEE MORE"}</a>
                              </div>
                              :null
                            :awardPageData?.length>2?
                              <div className='text-center see-more-btn'>
                                <a onClick={() => showMoreAwards()}>{loadMore? "SEE LESS":"SEE MORE"}</a>
                              </div>
                              :null
                        }
                  </section>
                  : null
                  :null
                  }
                  {
                    pageData.product_type === 'Pack' && checkForSlice('all_flavors') ?
                    <>
                      {/* <div>from slice</div> */}
                      <SliceZone slices={renderSliceType("all_flavors")} components={pdpComponents} />
                    </>
                    :
                    <div className="product-all-flavors">
                      {/* <div>from CR</div> */}
                      <h2 className="product-all-flavors__header">{pageData?.product_category?.document?.data?.category_name_prefix} {pageData?.product_category?.document?.data?.category_name} {pageData?.product_category?.document?.data?.category_name_suffix}</h2>
                      <div className="product-all-flavors__list">
                          {pageData?.product_category?.document?.data?.category_item &&
                          pageData?.product_category?.document?.data?.category_item.length>0
                          && pageData?.product_category?.document?.data?.category_item.map((element, index) => { // eslint-disable-line array-callback-return
                            const itemType = element?.category_item_link?.document?.data?.product_type
                            if(itemType !== 'Pack') {
                              return (
                                <CustomLink
                                    key={index}
                                    to={element?.category_item_link?.document?.url}
                                    className="product-all-flavors__item"
                                      onClick={()=>{
                                        const dl = {
                                          flavor: element?.category_item_link?.document?.data?.taxonomy_product_flavor,
                                          category: pageData?.product_category?.document?.data?.category_name?.toLowerCase() || '',
                                          position: index + 1,
                                          url: element?.category_item_link?.document?.url,
                                          location: 'product details page',
                                          size: element.category_item_link?.document?.data?.taxonomy_product_size
                                        }
                                        setProductFlavorClickedEvent(dl, consentAccepted)
                                      }}>
                                  <img className="product-all-flavors__item-image" src={element?.category_item_link?.document?.data?.product_image_png?.url}
                                            alt={element?.category_item_link?.document?.data?.product_image_png?.alt ?
                                                element?.category_item_link?.document?.data?.product_image_png?.alt :
                                                element?.category_item_link?.document?.data?.product_name} />
                                  <span className="product-all-flavors__item-name">{element?.category_item_link?.document?.data?.product_name?.text}</span>
                                  <span className="product-all-flavors__item-tag">{
                                    pageData.product_category?.document?.data?.category_new_text
                                    ? pageData.product_category?.document?.data?.category_new_text
                                    : element.category_item_link?.document?.data?.product_new_text
                                    ? element.category_item_link?.document?.data?.product_new_text
                                    : null
                                    }</span>
                                </CustomLink>
                              );
                            }
                        })}
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
            { lang === LANG.EN && <Reviews productId={productIdBV}/> }
            <ProductDetailsModules sliceDataOverRide={sliceData} activeDocMeta={activeDoc} vodkaSoda={isVodkaSoda} />
          </div>
        </section>
      </Layout>
    );
}

export const query = graphql`
  query ProductDetailsPageQuery($uid: String, $id: String, $lang: String) {
    prismicProductDetailsPage(uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        cta_button_link{
          url
        }
        cta_button_text{
          text
        }
        social_card {
          url
        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        product_name {
          text
          richText
        }
        product_free_text_element
        product_id
        product_name_color
        product_type
        product_section {
          document {
            ... on PrismicProductSection {
              id
              data {
                section_name
              }
            }
          }
        }
        product_category {
          document {
            ... on PrismicProductCategory {
              id
              data {
                category_name_prefix
                category_name
                category_name_suffix
                category_new_text
                category_info {
                  info_image {
                    url
                    alt
                  }
                  info_text
                }
                category_info_subtitle {
                  richText
                }
                category_item {
                  category_item_link {
                    document {
                      ... on PrismicProductDetailsPage {
                        id
                        url
                        data {
                          product_id
                          product_free_text_element
                          product_name {
                            text
                            richText
                          }
                          product_image_png {
                            url
                            alt
                          }
                          product_type
                          product_new_text
                          taxonomy_product_size
                          taxonomy_product_flavor
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        product_image_png {
          url
          alt
        }
        product_bg_png {
          url
          alt
        }
        product_action_title
        product_action_link {
          url
        }
        product_description {
          richText
        }
        product_info {
          product_info_icon {
            url
            alt
          }
          product_info_name
        }
        product_sizes_title
        product_sizes {
          product_size
          product_size_icon {
            url
            alt
          }
        }
        product_packs_title
        product_packs {
          product_pack
          product_pack_icon {
            url
            alt
          }
        }
        table_title
        serving_size_title
        serving_size_list {
          sizes
        }
        taxonomy_product_size
        taxonomy_product_flavor
        body {
          ... on PrismicProductDetailsPageDataBodyNutritionTable {
            id
            slice_type
            slice_label
            items {
              row_dark_underline
              row_indented
              row_large_header
              row_left
              row_no_underline
              row_bold_text_left
              row_right
            }
            primary {
              footer {
                richText
              }
            }
          }
          ... on PrismicProductDetailsPageDataBodyAllFlavors {
            id
            slice_type
            slice_label
            items {
              item_link {
                document {
                  ... on PrismicProductDetailsPage {
                    id
                    url
                    data {
                      product_id
                      product_new_text
                      product_free_text_element
                      product_name {
                        text
                        richText
                      }
                      product_image_png {
                        url
                        alt
                      }
                      taxonomy_product_flavor
                      taxonomy_product_size
                      product_category {
                        document {
                          ... on PrismicProductCategory {
                            id
                            data {
                              category_new_text
                              category_name
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            primary {
              header
            }
          }
          ... on PrismicProductDetailsPageDataBodyAwardList {
            id
            slice_type
            primary {
              show_awards
            }
            items {
              award_image {
                url
                alt
              }
              award_sub_image {
                alt
                url
              }
              award_sub_title {
                text
              }
              award_title {
                text
              }
            }
          }
          ... on PrismicProductDetailsPageDataBodyInstagramGallery {
            id
            slice_type
          }
          ... on PrismicProductDetailsPageDataBodyInstagramSection {
            id
            slice_type
            primary {
              title {
                text
              }
              description {
                text
              }
              container_class {
                text
              }
              background_color {
                text
              }
              copy_color {
                text
              }
            }
          }
          ... on PrismicProductDetailsPageDataBodyFeaturedProducts {
            id
            slice_type
            primary {
              header
            }
            items {
              item_name
              item_image {
                url
                alt
              }
              item_tag
              item_link {
                url
              }
            }
          }
          ... on PrismicProductDetailsPageDataBodyProductImageCarousel {
            id
            slice_label
            slice_type
            items {
              product_image {
                alt
                url
              }
              product_image_mobile {
                alt
                url
              }
              product_bg_image {
                alt
                url
              }
              product_bg_image_mobile {
                alt
                url
              }
          }
        }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
export default withPrismicPreview(ProductDetails)